<template>
  <div
    class="gallery-block"
  >
    <ts-row>
      <ts-col
        v-for="(image, idx) in config.gallery.images"
        :key="idx"
        :ts-w="colCount"
        ts-align="center"
      >
        <div class="image-wrapper">
          <img
            :src="image.url"
            :alt="isDecorativeImage(image) ? '' : image.alt_text"
            :style="{ height: imgHeight }"
            :class="{'lightbox-cursor': config.gallery.hasLightbox}"
            @click="config.gallery.hasLightbox ? openLightbox($event, config.gallery.images, idx) : ''"
          >
        </div>
        <div class="caption-container">
            <div
              v-if="image.caption && config.gallery.showCaptions"
              v-html="image.caption"
              class="caption"
              :style="{ textAlign: config.gallery.captionsAlignment }"
            />
         </div>
      </ts-col>
    </ts-row>
  </div>
</template>

<script>
import tsRow from '../../global/tsRow.vue';
import tsCol from '../../global/tsCol.vue';

export default {
  name: 'GalleryBlock',
  components: {
    tsRow,
    tsCol,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colCount: 1,
      height: null
    };
  },
  computed: {
    imgHeight() {
      return `${this.height}px`;
    }
  },
  mounted() {
    this.colCount = 12 / this.config.gallery.columns;
    this.resizeGallery();
    window.addEventListener('resize', this.resizeGallery);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeGallery);
  },
  methods: {
    resizeGallery() {
      this.$nextTick(() => {
        if (this.$el.querySelector('.image-wrapper') !== null) {
          this.height = this.$el.querySelector('.image-wrapper').clientWidth;
        }
      });
    },
    openLightbox(e, images, startingIndex) {
      this.$store.commit('setPrevFocusedElem', e.target);
      this.$store.commit('setLightBoxCurrentIndex', startingIndex);
      this.$store.commit('openLightbox');
      this.$store.commit('setLightboxImages', images);
    },
    isDecorativeImage(image) {
      return !!image.decorative_image;
    }
  }
};
</script>

<style scoped lang="scss">
.gallery-block {
  &.full-width {
    max-width: 100%;
  }
}
.image-wrapper {
  margin: 5px;
  overflow: hidden;
  z-index: 4;
}
img {
  width: 100%;
  object-fit: cover;
}
.lightbox-cursor {
  cursor: pointer;
}
</style>
