<template>
  <div class="table-block">
    <table v-if="table.orientation === 'vertical' && !isMobile">
      <thead>
        <tr>
          <th
            v-for="(header, index) in table.header"
            :key="`th-vertical-${index}`"
            scope="col"
            :class="{ 'bold': table.bold }"
          >
            <div
              :style="`text-align: ${table.header[index].align || table.textAlign}`"
              v-html="table.header[index].value"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, x) in table.matrix"
          :key="`tr-vertical-${x}`"
        >
          <td
            v-for="(col, y) in row"
            :key="`td-vertical-${y}`"
          >
            <div
              :style="`text-align: ${table.matrix[x][y].align || table.textAlign}`"
              v-html="table.matrix[x][y].value"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else>
      <tbody>
        <tr
          v-for="(row, x) in ((table.orientation === 'vertical' && isMobile) ? flippedMatrix : table.matrix)"
          :key="`tr-horizontal-${x}`"
        >
          <th
            v-if="table.header"
            scope="col"
            :class="{ 'bold': table.bold }"
          >
            <div
              :style="`text-align: ${table.header[x].align || table.textAlign}`"
              v-html="table.header[x].value"
            />
          </th>
          <td
            v-for="(col, y) in row"
            :key="`td-horizontal-${y}`"
          >
            <div
              :style="`text-align: ${(table.orientation === 'vertical' && isMobile ? flippedMatrix: table.matrix)[x][y].align || table.textAlign}`"
              v-html="(table.orientation === 'vertical' && isMobile ? flippedMatrix : table.matrix)[x][y].value"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableBlock',
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      table: {},
      flippedMatrix: [],
      width: ''
    };
  },
  computed: {
    countColumns() {
      return this.matrix[0].length;
    },
    isMobile() {
      return this.width < 601;
    }
  },
  created() {
    this.table = this.config.table;
    if (this.config.table.orientation === 'vertical') this.flipMatrix();
  },
  mounted() {
    this.resizeTable();
    window.addEventListener('resize', this.resizeTable);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeTable);
  },
  methods: {
    flipMatrix() {
      this.flippedMatrix = [...Array(this.table.columns)].map(() => Array(this.table.rows).fill({ value: '' }));
      this.table.matrix.forEach((row, x) => row.forEach((col, y) => {
        this.flippedMatrix[y][x] = this.table.matrix[x][y];
      }));
    },
    resizeTable() {
      this.width = window.innerWidth;
    }
  }
};
</script>

<style scoped lang="scss">
$breakpoint: 600px;
.table-block {
  margin: 10px auto;
  :deep(p) {
    margin-bottom: 19px;
    line-height: 1.5;
    white-space: pre-wrap;
    &:last-of-type {
      margin-bottom: 0px;
    }
    &:empty {
      height: 16px;
    }
  }
  td :deep(ul) {
    margin-bottom: 19px;
    li p:empty {
        height: 0;
    }
    li p {
      margin: 0;
    }
    &:last-child {
        margin-bottom: 0px;
    }
  }
  td :deep(ol) {
    margin-bottom: 19px;
    li p:empty {
        height: 0;
    }
    li p {
      margin: 0;
    }
    &:last-child {
        margin-bottom: 0px;
    }
  }
  table {
    width: 100%;
    height: fit-content;
    table-layout: fixed;
    overflow-y: scroll;
    border-collapse: collapse;
    border: 1px solid #000;
    word-wrap: break-word;
    tr {
      td {
        border: 1px solid #000;
        padding: 15px 20px;
        vertical-align: top;
      }
    }
    th {
      border: 1px solid #000;
      padding: 15px 20px;
      font-weight: 400;
      vertical-align: top;
      &.bold {
        font-size: 20px;
        font-weight: 700;
      }
    }
    @media (max-width: $breakpoint) {
      tr {
        border: 1px solid #000;
        td {
          display: block;
          div {
            &:before {
              display: inline-block;
              font-weight: bold;
              @media (min-width: $breakpoint) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
