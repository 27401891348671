<template>
  <div>
    <div
      class="image-container"
      :class="config.image.image_alignment"
    >
      <a
        v-if="config.image.action_on_click === 'link'"
        class="image-container-link"
        :href="config.image.link_url"
        :target="config.image.open_link_in_new_tab ? '_blank' : '_self'"
        :style="{ width: imgWidth }"
      >
        <img
          :src="config.image.url"
          :alt="isDecorativeImage ? '' : config.image.alt_text"
        >
      </a>
      <img
        v-else
        :src="config.image.url"
        :alt="isDecorativeImage ? '' : config.image.alt_text"
        :style="{ width: imgWidth }"
        @click="config.image.action_on_click === 'lightbox' ? openLightbox($event, config.image) : ''"
      >
    </div>
    <p :class="config.image.caption_alignment">
      {{ config.image.caption }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgWidth() {
      if (this.config.image.display === 'percentage') {
        return `${this.config.image.set_ratio * 100}%`;
      }
      if (this.config.image.display === 'actual-size') {
        return `${this.config.image.cropped_width}px`;
      }
      return `${this.config.image.preferred_width}px`;
    },
    isDecorativeImage() {
      return !!this.config.image?.decorative_image;
    }
  },
  methods: {
    openLightbox(e, image) {
      this.$store.commit('setPrevFocusedElem', e.target);
      this.$store.commit('setLightBoxCurrentIndex', 0);
      this.$store.commit('openLightbox');
      this.$store.commit('setLightboxImages', [image]);
    }
  }
};
</script>

<style scoped lang="scss">
.image-container {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  &.right {
    justify-content: flex-end;
  }
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  .image-container-link {
    img {
      width: 100%;
    }
   }
 }
p {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding-top: 10px;
  word-wrap: break-word;
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
}
img {
  max-width: 100%;
}
</style>
