<template>
  <div class="login-container">
    <div class="box">
      <h2>{{ $t('pages.forms.signIn') }}</h2>
      <form @submit.prevent="login">
        <div class="password-protected">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label>
            {{ $t('pages.forms.password') }}
            <span class="required-element">*</span>
            <input
              v-model="password"
              type="password"
              class="input-password-protected"
              :class="{ error: incorrectPassword }"
              required
            >
          </label>
          <span
            v-if="incorrectPassword"
            class="error-message"
          >
            {{ $t('pages.forms.incorrectPassword') }}
          </span>
        </div>
        <button
          class="btn-submit"
          :disabled="isLoading"
          type="submit"
        >
          <span v-if="isLoading">
            {{ $t('pages.shared.loading') }}
            <span class="loading-spinner" />
          </span>
          <span v-else>{{ $t('pages.forms.signIn') }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    incorrectPassword: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: '',
    };
  },
  methods: {
    login() {
      this.$emit('submit-password', this.password);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../components/global/scss/mixins/breakpoints';
.login-container {
  height: 70vh;
  width: 100%;
  min-height: 400px;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $md) {
    min-height: 500px;
  }
  .box {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    flex-direction: column;
    max-width: 392px;
    width: 80%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 32px;
    @media screen and (min-width: $md) {
      width: 392px;
      height: 260px;
    }
    h2 {
      margin-top: 15px;
      text-align: center;
      line-height: 32px;
      letter-spacing: 0.8px;
    }
    form {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      .password-protected {
        margin-bottom: 30px;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
        .input-password-protected {
          all: unset;
          width: 100%;
          height: 40px;
          background-color: #fbfbfb;
          border-bottom: 1px solid #747474;
          &.error {
            border: 1px solid #cc314b;
          }
        }
        .error-message {
          color: #cc314b;
          font-size: 12px;
        }
        .required-element {
          color: #cc314b;
        }
      }
      .btn-submit {
        width: 100%;
        height: 48px;
        padding: 10px;
        background-color: #014990;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        border: none;
        border-radius: 8px;
        margin-top: 10px;
        &:hover {
          background-color: #0056b3;
        }
        &[disabled] {
          cursor: not-allowed;
        }
        .loading-spinner {
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-top: 2px solid #fff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 1s linear infinite;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
