<template>
  <div
    ref="accordion"
    class="accordion-block"
    data-testid="Accordion"
  >
    <div
      v-for="panel in panels"
      :key="panel.id"
      class="accordion-panel"
    >
      <AccordionPanel
        :item="panel"
        :theme="config.accordion.theme"
        :globals="globals"
        :col-index="colIndex"
        :icon="config.accordion.icon_style"
        @toggle-panel="
          config.accordion.allow_multiple_open_panels ? toggleSinglePanel(panel.id) : toggleCurrentlyOpenPanel(panel.id)
        "
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import AccordionPanel from './AccordionPanel';

export default {
  name: 'AccordionBlock',
  components: {
    AccordionPanel,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    rowType: {
      type: String,
      default: '',
    },
    colIndex: {
      type: Number,
      required: true,
    },
    globals: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panels: [],
      icon: '',
    };
  },
  created() {
    this.icon = this.config.accordion.icon_style;
    this.panels = cloneDeep(this.config.accordion.items);
    this.panels = this.panels.map((panel) => ({ ...panel, expanded: false }));
  },
  methods: {
    toggleCurrentlyOpenPanel(id) {
      this.panels = this.panels.map((panel) =>
        panel.id === id ? { ...panel, expanded: !panel.expanded } : { ...panel, expanded: false },
      );
    },
    toggleSinglePanel(id) {
      this.panels = this.panels.map((panel) =>
        panel.id === id ? { ...panel, expanded: !panel.expanded } : { ...panel },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
div.accordion-block {
  top: 40px;
  padding-bottom: 1px;
  margin: 0 auto;
}
</style>
