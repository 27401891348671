<template>
  <div>
    <div
      :style="styles"
      class="ts-divider"
    />
  </div>
</template>

<script>
export default {
  name: 'DividerBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    styles() {
      return {
        borderBottom: `solid ${this.config.divider.thickness}px ${this.config.divider.color.hex}`,
        width: `${this.config.divider.width}%`,
      };
    },
  },
};
</script>

<style scoped>
.ts-divider {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: block;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
