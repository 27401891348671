<template>
  <div
    class="collapsible-section"
    :class="theme"
    :style="cssVars"
  >
    <h2>
      <button
        :id="`section-${item.id}`"
        class="section-header"
        :aria-controls="item.expanded ? `panel-${item.id}` : null"
        :aria-expanded="item.expanded.toString()"
        @click="$emit('toggle-panel')"
      >
        <div class="header-text">
          {{ item.text }}
        </div>
        <component
          :is="panelIcon"
          :class="`${panelIcon}${!!item.expanded ? '-open' : '-closed'}`"
          :fill-color="collapseIconColor"
          :expanded="!!item.expanded"
        />
      </button>
    </h2>
    <div
      v-if="item.expanded"
      :id="`panel-${item.id}`"
      role="region"
      :aria-labelledby="`section-${item.id}`"
    >
      <section class="content-section-dropzone">
        <div
          v-for="block in item.content"
          :key="block.id"
          :style="blockStyle(block)"
          class="block"
        >
          <RowRenderer
            v-if="isLayoutBlock(block)"
            :row="block"
            :in-accordion="true"
            :level="1"
            nested-row
          />
          <ContentRenderer
            v-else
            :block="block"
            :level="1"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { defineAsyncComponent } from 'vue';
import { blockStyle, isLayoutBlock } from '../helpers/page-builder-helpers';
import Caret from '../../global/svg/Caret.vue';
import Chevron from '../../global/svg/Chevron.vue';
import PlusMinus from '../../global/svg/PlusMinus.vue';

export default {
  components: {
    Caret,
    Chevron,
    PlusMinus,
    RowRenderer: defineAsyncComponent(() => import('../RowRenderer.vue')),
    ContentRenderer: defineAsyncComponent(() => import('../ContentRenderer.vue')),
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    globals: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    rowType: {
      type: String,
      default: '',
    },
    colIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['toggle-panel'],
  computed: {
    headingColor() {
      return `${get(this.globals.info, 'cms_heading_color.hex', '#333333')}`;
    },
    pageBuilderThemeColor() {
      return `${get(this.globals.info, 'page_builder_theme_color.hex', this.headingColor)}`;
    },
    cssVars() {
      return {
        '--section-header-light': `${this.pageBuilderThemeColor}10`,
        '--section-header-color': this.pageBuilderThemeColor,
      };
    },
    collapseIconColor() {
      return this.theme === 'dark' ? '#fff' : this.pageBuilderThemeColor;
    },
    panelIcon() {
      return this.icon === 'minus' || this.icon === 'plus' ? 'plus-minus' : this.icon;
    },
  },
  methods: {
    isLayoutBlock,
    blockStyle,
  },
};
</script>

<style lang="scss" scoped>
.collapsible-section {
  margin: 8px 0px;
  .section-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.8px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 8px;
    text-align: inherit;
    &:focus {
      outline-offset: -5px;
    }
  }
  .header-text {
    width: fit-content;
  }
}
.content-section-dropzone {
  margin: 0 7.5px;
  display: flex;
  flex-direction: column;
}
.default {
  .section-header {
    border: none;
    border-radius: 0;
    background-color: white;
    border-bottom: 1px solid #e1e1e1;
    color: var(--section-header-color);
  }
}
.light {
  .section-header {
    background: var(--section-header-light);
    color: var(--section-header-color);
    border: none;
    &::placeholder,
    &::-moz-placeholder {
      color: var(--section-header-color);
    }
  }
}
.dark {
  .section-header {
    background: var(--section-header-color);
    color: #fff;
    border: none;
    &::placeholder,
    &::-moz-placeholder {
      color: #fff;
    }
  }
}
.block {
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
}
</style>
