<template>
  <div
    class="text-block"
    :style="`text-align: ${config.align}`"
  >
    <div v-html="config.content" />
  </div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped lang="scss">
.text-block :deep() {
  p,
  ul,
  ol,
  blockquote {
    &:last-child {
      margin-bottom: 0px;
    }
  }
  p {
    margin-bottom: 19px;
    line-height: 1.5;
    white-space: pre-wrap;
    &:empty {
      height: 16px;
    }
  }
  ul,
  ol {
    margin-bottom: 19px;
    li p {
      margin: 0;
      &:empty {
        height: 0;
      }
    }
  }
  blockquote {
    &:last-child {
      p {
        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
    p {
      &:last-of-type {
        margin-bottom: 19px;
      }
    }
  }
  code {
    font-family: SFMono-Medium, 'SF Mono', 'Segoe UI Mono', 'Roboto Mono',
      'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
    font-weight: normal;
    font-size: 16px;
    background-color: rgba(9, 30, 66, 0.08);
    color: #172b4d;
    border-style: none;
    border-radius: 3px;
    display: inline;
    padding: 2px 0.5ch;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: auto;
    -webkit-box-decoration-break: clone;
  }
}
</style>
