<template>
  <div>
    <div class="iframe-wrapper">
      <iframe
        v-if="video.type === 'vimeo'"
        :src="`https://player.vimeo.com/video/${video.id}`"
        :title="video.title"
        class="video-iframe"
        allowfullscreen
      />
      <iframe
        v-else
        :src="`https://www.youtube.com/embed/${video.id}`"
        :title="video.title"
        class="video-iframe"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      video: {},
    };
  },
  created() {
    this.video = this.parseVideo(this.config.video.url);
  },
  methods: {
    parseVideo(url) {
      // - Supported YouTube URL formats:
      //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
      //   - http://youtu.be/My2FRPA3Gf8
      //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
      // - Supported Vimeo URL formats:
      //   - http://vimeo.com/25451551
      //   - http://player.vimeo.com/video/25451551
      // - Also supports relative URLs:
      //   - //player.vimeo.com/video/25451551
      let type = null;
      url.match(
        /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
      );
      if (RegExp.$3.indexOf('youtu') > -1) {
        type = 'youtube';
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        type = 'vimeo';
      }

      return {
        type,
        id: RegExp.$6,
        title: this.config?.video?.title || '',
      };
    },
  },
};
</script>

<style scoped>
.iframe-wrapper {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}
.iframe-wrapper iframe {
  border-style: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
