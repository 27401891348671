<template>
  <div>
    <component
      :is="listType"
      :class="{ 'is-format-none': config.list.format === 'none' }"
      data-cy="list-ul"
    >
      <li
        v-for="item in config.list.items"
        :key="item.id"
        data-cy="list-li"
        class="list-group"
      >
        <div
          class="list-item"
          data-cy="list-content"
          v-html="item.content"
        />
        <div
          v-if="config.list.design === 'detailed'"
          class="list-details"
          data-cy="list-details"
          v-html="item.details"
        />
      </li>
    </component>
  </div>
</template>

<script>
export default {
  name: 'ListBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    listType() {
      return this.config.list.format === 'decimal' ? 'ol' : 'ul';
    },
  }
};
</script>

<style scoped>
.is-format-none {
  list-style-type: none;
}
ul, ol {
  margin-top: 0px;
  margin-bottom: 0px;
}
li {
  padding: 0 5px 5px;
  font-size: 16px;
}
.list-item,
.list-details {
  width: 100%;
  display: block;
  border-bottom: none;
  background: none;
}
.list-details {
  font-size: 14px;
  margin-top: 5px;
}
.list-group {
  padding-bottom: 10px;
} :last-of-type {
  padding-bottom: 0px;
}
</style>
