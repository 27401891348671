<template>
  <div
    class="heading-block"
    :style="cssVars"
  >
    <div v-html="config.content" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeadingBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    globals: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['getGlobals']),
    cssVars() {
      return {
        '--text-align': this.config.align,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.heading-block {
  text-align: var(--text-align);
  color: var(--heading-color);
}
</style>
