<template>
  <div
    class="quote-block"
    :class="[config.quote.design]"
    :style="[isInSidebar ? {
      'padding-left' : '30px',
      'border-color': config.quote.color.hex
    } : {
      'padding-left' : '60px',
      'border-color': config.quote.color.hex
    }]"
  >
    <div
      v-if="config.quote.design === 'design-2'"
      :style="{'color': config.quote.color.hex}"
      class="design-2-quotes"
    />
    <blockquote
      :cite="config.quote.source"
    >
      <p class="content">{{ config.quote.content }}</p>
    </blockquote>
    <cite
      v-if="isInSidebar"
      :style="{'padding-bottom' : '60px'}"
    >
      <p>{{ config.quote.author }}, <br>{{ config.quote.source }}</p>
    </cite>
    <cite
      v-else
      :style="{'padding-bottom' : '40px'}"
    >
      <p>
        {{ config.quote.author }} &nbsp;
      </p>
      <span class="divider" />
      <p
        v-if="config.quote.source"
        class="quote-source"
      >
        &nbsp;&nbsp; {{ config.quote.source }}
      </p>
    </cite>
  </div>
</template>

<script>
export default {
  name: 'QuoteBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isInSidebar() {
      return this.config.blockLocation === 'sidebar';
    },
  },
};
</script>

<style scoped lang="scss">
.quote-block {
  padding: 6px 0px 6px 6px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  font-style: italic;
  font-size: 14px;
  color: #333333;
  height: auto;
  clear: both;
  p {
    display: inline;
  }
  .content {
    white-space: pre-wrap;
  }
  .divider {
      position: relative;
      display: inline-block;
      top: 7px;
      width: 0px;
      height: 22px;
      border-left: 1px solid #333;
  }
  &.design-1 {
    border-left: 4px solid #a82a33;
    blockquote {
      display: block;
      font-size: 18px;
      font-style: italic;
      width: 100%;
      line-height: 24px;
      font-weight: 600;
      margin: 30px 0 14px 0px;
    }
    cite {
      display: block;
      padding: 0;
      color: #333333;
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      max-height: 100%;
    }
  }
  &.design-2 {
    position: relative;
    blockquote {
      display: block;
      font-size: 18px;
      font-style: italic;
      line-height: 24px;
      margin: 30px 0px 14px 0;
      font-weight: 600;
      width: 100%;
    }
    cite {
      display: block;
      height: 12px;
      color: #333333;
      font-size: 14px;
      line-height: 22px;
      font-style: normal;
    }
  }
  .design-2-quotes {
    &:before {
      content: '“';
      height: 24px;
      width: 100%;
      font-size: 62px;
      font-style: italic;
      line-height: 24px;
      position: absolute;
      left: 15px;
      padding: 0;
      top: 30px;
    }
  }
  &.design-3 {
    border-left: 2px solid #a82a33;
    blockquote {
      color: #333;
      margin: 30px 0 30px 0;
      display: block;
      width: 100%;
      font-size: 16px;
      font-style: italic;
      line-height: 24px;
      padding: 0;
    }
    cite {
      display: none;
    }
  }
}
</style>
