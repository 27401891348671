<template>
  <div
    class="embed-block"
    :class="config.alignment"
  >
    <div
      ref="embed"
      class="embed-block--wrapper"
    />
  </div>
</template>

<script>
import * as cheerio from 'cheerio';

export default {
  name: 'EmbedBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.parseAndMountCode();
  },
  methods: {
    parseAndMountCode() {
      try {
        const $ = cheerio.load(this.config.embed);
        $('a').each((index, element) => {
          const href = $(element).attr('href');
          if (!href) return;

          $(element).attr('href', this.swapQuotes(href));
        });
        this.$refs.embed.innerHTML = $.html();

        // no idea why, but scripts only work in accordions when we re-parse them and replace theme entirely
        const scripts = this.$refs.embed.querySelectorAll('script');
        scripts.forEach((originalScript) => {
          const newScript = document.createElement('script');

          // Copy all attributes from the original script to the new one
          Array.from(originalScript.attributes).forEach((attr) => {
            newScript.setAttribute(attr.name, attr.value);
          });

          // Set the content of the script if it's inline (no `src` attribute)
          if (!newScript.src) {
            newScript.textContent = originalScript.textContent;
          }

          originalScript.parentNode.replaceChild(newScript, originalScript);
        });
      } catch (err) {
        console.error(err);
      }
    },
    swapQuotes(str) {
      return str.replace(/"/g, '~~"~~').replace(/'/g, '"').replace(/~~"~~/g, "'");
    },
  },
};
</script>
<style scoped lang="scss">
iframe {
  display: none;
}
.embed-block {
  display: flex;
  .embed-block--wrapper {
    width: 100%;
  }
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
}
</style>
