<template>
  <div
    class="card-block"
    :class="[responsiveClass, rowType, {
      'shadow': config.card.design == 'box-shadow',
      'separator': config.card.design == 'separator',
      'image-right': config.card.imageRight,
      'wrap': config.card.wrapText,
      'has-image': config.card.hasImage,
      'none': config.card.design == 'none'
    }]"
  >
    <div
      v-if="config.card.hasImage"
      class="card-left"
    >
      <img
        :src="config.card.image.url"
        :alt="isDecorativeImage ? '' : config.card.image.alt_text"
      >
    </div>
    <div class="card-text">
      <div
        v-if="config.card.hasHeadline"
        class="headline"
        :class="config.card.headlineAlignment"
        v-html="config.card.headline"
      />
      <div
        class="content"
        :class="config.card.textAlignment"
        v-html="config.card.content"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CardBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    rowType: {
      type: String,
      default: ''
    },
    colIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getGlobals']),
    responsiveClass() {
      if ((this.rowType === 'ts-left-sidebar' && this.colIndex === 0)
      || (this.rowType === 'ts-right-sidebar' && this.colIndex === 1)
      || this.rowType === 'ts-three-column'
      || this.rowType === 'ts-four-column') {
        return 'stacked-card';
      }
      return '';
    },
    isDecorativeImage() {
      return !!this.config.card.image?.decorative_image;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin responsive-card {
  &.card-block {
    flex-direction: column;
    padding: 5px;
    .card-left {
      width: 100%;
    }
    .card-text {
      width: 95%;
      padding: 5px 10px;
      padding-bottom: 35px;
      margin: 0;
      .headline {
        padding: 0;
        margin-bottom: 10px;
      }
      .content {
        margin-right: 0;
        margin-left: 0;
        padding-right: 10px;
        ol {
          margin-bottom: 19px;
          margin-left: 1em;
        }
      }
    }
    &:not(.has-image) {
      .card-text {
        padding-top: 30px;
      }
    }
  }
}
div.card-block {
  padding: 35px;
  margin: 5px;
  width: auto;
  height: auto;
  font-weight: 400;
  font-family: inherit;
  letter-spacing: 0.5px;
  overflow: hidden;
  vertical-align: baseline;
  font-size: 16px;
  line-height: 1.5;
  &:not(.wrap) {
    display: flex;
    .card-text {
      width: 100%;
    }
    .card-left {
      align-items: center;
      position: relative;
      z-index: 1;
      display: flex;
    }
    &:not(.image-right) {
      .content {
        margin-left: 20px;
      }
    }
    &:not(.has-image) {
      .content {
        margin-left: 0;
      }
      .headline {
        padding-left: 0;
      }
    }
  }
  &.wrap {
    .card-left {
      float: left;
    }
    .card-text {
      overflow: visible;
      .headline {
        padding: 0;
        margin-bottom: 10px;
      }
    }
    &:not(.image-right) {
      .card-left {
        margin-right: 20px;
      }
      .card-text {
        .content {
          :deep(li) {
            position: relative;
            left: 1em;
            padding-right: 1em;
          }
        }
      }
    }
    &.image-right {
      @media only screen and (min-width: 450px) {
        .card-left {
          float: right;
          padding-left: 20px;
          padding-right: 0px;
        }
      }
    }
  }
  .card-left {
    img {
      width: 215px;
      height: 215px;
      object-fit: cover;
    }
  }
  .card-text {
    .headline {
      padding-left: 20px;
      padding-right: 0px;
      margin-bottom: 10px;
      color: var(--heading-color);
    }
    .right {
      text-align: right;
    }
    .left {
      text-align: left;
    }
    .center {
      text-align: center;
    }
  }
  &:not(.has-image) {
    .card-text {
      width: 100%;
      margin: 0;
    }
  }
  &.shadow {
    box-shadow: 1px 1px 10px #ccc;
    border: .1px solid #ccc;
  }
  &.separator {
    padding-bottom: 35px;
    border-bottom: 2px solid #dddddd;
  }
  &.none {
    padding: 0;
    margin: 0;
  }
  &.image-right {
    flex-direction: row-reverse;
    .card-text {
      .headline {
        padding-right: 20px;
        padding-left: 0px;
        margin-bottom: 10px;
      }
      .content {
        margin-right: 20px;
      }
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1900px) {
    &.stacked-card {
      @include responsive-card;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 960px) {
      &.ts-two-column {
        @include responsive-card;
    }
  }
  @media only screen and (max-width: 450px) {
    @include responsive-card;
  }
  .content :deep(p) {
    margin-bottom: 19px;
  }
  .content :deep(p:empty) {
    height: 16px;
  }
  .content :deep(li > p:empty) {
    height: 0;
    margin: 0;
  }
  .content :deep(ul) {
    margin-bottom: 19px;
    li > p {
      margin: 0;
    }
  }
  .content :deep(ol) {
    margin-bottom: 19px;
    li > p {
      margin: 0;
    }
  }
  .content :deep(p, ul, ol, blockquote) {
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
