<template>
  <div
    ref="block"
    class="slider-block"
  >
    <button
      v-if="showPauseButton"
      class="pause-button"
      :style="{ left: config.slider.arrow_position === 'sides' ? '70px' : '15px' }"
      :aria-label="isSliderPaused ? 'Play slider autoscroll' : 'Pause slider autoscroll'"
      @click="pauseUnpauseSlider"
    >
      <img
        v-if="!isSliderPaused"
        src="../../../assets/pause.svg"
        alt=""
      />
      <img
        v-else
        src="../../../assets/play.svg"
        alt=""
      />
    </button>
    <span
      aria-live="polite"
      class="sr-only"
      >{{ isSliderPaused ? 'Slider is paused' : 'Slider is playing' }}</span
    >
    <div
      v-if="config.slider.images.length > 1 && config.slider.has_arrows"
      class="slider-arrows"
    >
      <button
        class="left-arrow"
        aria-label="Previous Slide"
        @click="moveLeft()"
      >
        <img
          src="../../../assets/left_arrow.svg"
          alt=""
        />
      </button>
      <button
        class="right-arrow"
        aria-label="Next Slide"
        @click="moveRight()"
      >
        <img
          src="../../../assets/right_arrow.svg"
          alt=""
        />
      </button>
    </div>
    <transition-group
      tag="div"
      :name="scrollDirection"
      :class="{ 'toggle-arrow-position': config.slider.arrow_position === 'sides' }"
      class="img-slider"
    >
      <img
        v-if="config.slider.images.length > 0"
        :key="active"
        :src="config.slider.images[active].url"
        :alt="isDecorativeImage(config.slider.images[active]) ? '' : config.slider.images[active].alt_text"
        class="image"
        @click="onImageClick($event, config.slider.images[active])"
      />
    </transition-group>
    <div
      v-if="config.slider.images.length > 1 && config.slider.has_dots"
      :class="{ 'dots-top': config.slider.dot_position === 'top' }"
      class="slider-dots"
    >
      <ul class="slider-nav">
        <li
          v-for="(dot, index) in config.slider.images"
          :key="index"
          :class="{ active: index === active }"
          @click="jump(index)"
        >
          <span class="circle" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      width: null,
      scrollDirection: 'right',
      isSliderPaused: false,
    };
  },
  computed: {
    showPauseButton() {
      return this.config?.slider?.images?.length > 1 && this.config.slider.is_autoplay;
    },
  },
  created() {
    this.autoScroll();
  },
  mounted() {
    this.resizeSlider();
    window.addEventListener('resize', this.resizeSlider);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeSlider);
  },
  methods: {
    resizeSlider() {
      this.$nextTick(() => {
        if (this.$refs.block.clientWidth !== null) {
          this.width = this.$refs.block.clientWidth;
        }
      });
    },
    jump(index) {
      this.active = index;
    },
    moveRight() {
      this.scrollDirection = 'right';
      this.move(1);
    },
    moveLeft() {
      this.scrollDirection = 'left';
      this.move(-1);
    },
    move(amount) {
      const totalSlides = this.config.slider.images.length - 1;
      let newIndex = this.active + amount;
      if (newIndex > totalSlides) newIndex = 0;
      if (newIndex < 0) newIndex = totalSlides;
      this.active = newIndex;
    },
    autoScroll() {
      let slideTimer;
      if (this.config.slider.is_autoplay && !this.isSliderPaused) {
        slideTimer = setTimeout(() => {
          if (this.isSliderPaused) return;
          this.move(1);
          this.autoScroll();
        }, this.config.slider.transition * 1000);
      } else {
        clearTimeout(slideTimer);
      }
    },
    openLightbox(e, images, startingIndex) {
      this.$store.commit('setPrevFocusedElem', e.target);
      this.$store.commit('setLightBoxCurrentIndex', startingIndex);
      this.$store.commit('openLightbox');
      this.$store.commit('setLightboxImages', images);
    },
    onImageClick(e, image) {
      if (image.action_on_click === 'lightbox') {
        const allLightboxImages = this.config.slider.images.filter((img) => img.action_on_click === 'lightbox');
        this.openLightbox(e, allLightboxImages, allLightboxImages.indexOf(image));
      } else if (image.action_on_click === 'link') {
        window.open(image.link_url, image.open_link_in_new_tab ? '' : '_self');
      }
    },
    isDecorativeImage(image) {
      return !!image.decorative_image;
    },
    pauseUnpauseSlider() {
      this.isSliderPaused = !this.isSliderPaused;
      this.autoScroll();
    },
  },
};
</script>

<style scoped lang="scss">
$image-ratio: 59vw;
$slider-btn-height: 54px;
.right-leave-active,
.right-enter-active,
.left-leave-active,
.left-enter-active {
  transition: 1s;
}
.right-enter-from {
  transform: translateX(100%);
}
.right-leave-to {
  transform: translateX(-100%);
}
.left-enter-from {
  transform: translateX(-100%);
}
.left-leave-to {
  transform: translateX(100%);
}
.slider-block {
  position: relative;
  overflow: hidden;
  &.full-width {
    max-width: 100%;
  }
}
.slider-block .slider-arrows {
  position: absolute;
  width: 100%;
  padding-bottom: 56.25%;
}
.slider-block .slider-arrows button {
  position: absolute;
  top: 60%;
  background: #fff;
  z-index: 1;
  border: none;
}
.slider-block .slider-arrows button:hover {
  background: #ddd;
  cursor: pointer;
}
.slider-block .slider-arrows .left-arrow {
  top: 50%;
  left: 0;
  margin-left: 5px;
  margin-top: calc(-#{$slider-btn-height}/ 2);
  align-items: center;
  width: 32px;
  height: $slider-btn-height;
  display: flex;
  justify-content: center;
}
.slider-block .slider-arrows .right-arrow {
  top: 50%;
  right: 0;
  margin-right: 5px;
  margin-top: calc(-#{$slider-btn-height}/ 2);
  align-items: center;
  width: 32px;
  height: $slider-btn-height;
  display: flex;
  justify-content: center;
}
.slider-block .slider-arrows img {
  width: 10px;
}
.slider-block .img-slider {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
}
.slider-block .img-slider.toggle-arrow-position {
  margin: 0 auto;
  width: 90%;
  @media screen and (max-width: 1100px) {
    width: 85%;
  }
  @media screen and (max-width: 900px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
.slider-block img.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
.slider-block .slider-dots {
  display: table;
  margin: 10px auto;
  position: relative;
}
.slider-block div.slider-dots.dots-top {
  top: -55px;
}
.slider-block .slider-dots ul {
  list-style: none;
  padding-left: 0;
}
.slider-block .slider-dots ul li {
  float: left;
  margin: 0 3px;
}
.slider-block .slider-dots ul li .circle {
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid #929292;
  border-radius: 50%;
  background: #929292;
}
.slider-block .slider-dots ul li .circle:hover {
  border: 1px solid #fff;
  background: #ddd;
  cursor: pointer;
}
.slider-block .slider-dots ul li.active .circle {
  background: #fff;
}
.pause-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: white;
  border: none;
  position: absolute;
  z-index: 1;
  bottom: 60px;
  cursor: pointer;
}
.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
</style>
