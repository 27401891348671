<template>
  <div
    class="button-block"
    :class="config.button.alignment"
    :style="cssVars"
  >
    <a
      v-for="(btn, idx) in config.button.buttons"
      :key="idx"
      :href="btn.mailto ? `mailto:${btn.link}` : btn.link"
      :target="!!btn.open_link_in_new_tab ? '_blank' : '_self'"
      :class="[
        config.button.type,
        config.button.theme,
        `size-${config.button.size}`,
        `style-${config.button.style}`,
        config.button.pill && 'pill',
      ]"
      class="button"
    >
      <div
        v-if="iconsActive"
        class="icon-wrapper"
      >
        <span class="raw-svg">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="btn.icon" />
        </span>
      </div>
      <ButtonTopBar
        v-if="displayStyle2Icons"
        class="bar"
      />
      <div class="title">{{ btn.title }}</div>
      <ButtonArrow
        v-if="displayStyle2Icons"
        :fill-color="pageBuilderThemeColor"
        :show-border="config.button.theme !== 'dark'"
        class="arrow-button"
      />
      <ArrowPointer
        v-if="config.button.type === 'link'"
        :fill-color="pageBuilderThemeColor"
      />
    </a>
  </div>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'ButtonBlock',
  components: {
    ArrowPointer: defineAsyncComponent(() => import('../../global/svg/ArrowPointer.vue')),
    ButtonArrow: defineAsyncComponent(() => import('../../global/svg/ButtonArrow.vue')),
    ButtonTopBar: defineAsyncComponent(() => import('../../global/svg/ButtonTopBar.vue')),
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    headingColor() {
      return get(this.$store.getters.getGlobals, 'info.cms_heading_color.hex', '#000');
    },
    pageBuilderThemeColor() {
      return get(this.$store.getters.getGlobals, 'info.page_builder_theme_color.hex', this.headingColor);
    },
    cssVars() {
      return {
        '--light': `${this.pageBuilderThemeColor}10`,
        '--light-hover': `${this.pageBuilderThemeColor}33`,
        '--dark': this.pageBuilderThemeColor,
        '--dark-hover': `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${this.pageBuilderThemeColor}`,
      };
    },
    iconsActive() {
      return this.config.button.type === 'square' && this.config.button.style !== '2' && this.config.button.has_icons;
    },
    displayStyle2Icons() {
      return this.config.button.type === 'square' && this.config.button.style === '2';
    },
  },
};
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  flex-wrap: wrap;
  &.center {
    justify-content: center;
  }
}
.button {
  min-height: 40px;
  text-decoration: none;
  display: flex;
  margin: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  &:focus {
    outline-offset: -5px;
  }
  .icon-wrapper {
    height: 40px;
    margin: 17px 0;
    display: flex;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    .raw-svg > * {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .raw-svg :deep(svg) {
      width: 40px;
      height: 40px;
      fill: var(--dark);
    }
  }
  &.light {
    background-color: var(--light);
    border-radius: 1px;
    &:hover {
      background-color: var(--light-hover);
    }
  }
  &.dark {
    background-color: var(--dark);
    border-radius: 4px;
    .title {
      color: #fff;
    }
    &:hover {
      background: var(--dark-hover);
    }
    .icon-wrapper .raw-svg :deep(svg) {
      fill: #fff;
    }
  }
  &.outline {
    border: var(--dark) solid 1px;
    border-radius: 4px;
    margin: 3px 4px;
    &:hover {
      background-color: var(--dark);
      .title {
        color: #fff;
      }
      &.style-2 .bar,
      .icon-wrapper .raw-svg :deep(svg) {
        fill: #fff;
      }
    }
  }
  &.square {
    min-height: 160px;
    min-width: 160px;
    max-width: 234px;
    margin-right: 20px;
    flex-direction: column;
    &.style-2 {
      min-height: 199px;
      justify-content: normal;
      align-items: normal;
      &.dark .bar {
        fill: #fff;
      }
      .bar {
        position: absolute;
        margin: 20px;
        width: calc(100% - 40px);
        fill: var(--dark);
      }
      .arrow-button {
        position: absolute;
        bottom: 23px;
        left: 26px;
      }
      .title {
        text-align: left;
        margin-bottom: 78px;
        margin-top: 43px;
        margin-left: 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  &.regular {
    &.pill {
      border-radius: 40px;
    }
    &.size-large .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 14px 32px;
    }
  }
  &.link {
    background: none;
    border: none;
    .title {
      color: var(--dark);
      margin-right: 24px;
    }
    &:hover {
      background: none;
      text-decoration: underline;
      text-decoration-color: var(--dark);
      .title {
        color: var(--dark);
      }
    }
  }
  .title {
    color: var(--dark);
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin: 6px 32px;
  }
}
</style>
