<template>
  <component
    :is="block.component"
    :config="block.config"
    :globals="getGlobals"
    :row-type="rowType"
    :col-index="colIndex"
    :class="[`content-level-${level}`, block.component]"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Lightbox from '../global/Lightbox.vue';
import tsCol from '../global/tsCol.vue';
import tsRow from '../global/tsRow.vue';
import Breadcrumbs from '../global/Breadcrumbs.vue';
import AccordionSectionBlock from './blocks/AccordionSectionBlock.vue';
import ButtonBlock from './blocks/ButtonBlock.vue';
import CardBlock from './blocks/CardBlock.vue';
import DividerBlock from './blocks/DividerBlock.vue';
import EmbedBlock from './blocks/EmbedBlock.vue';
import GalleryBlock from './blocks/GalleryBlock.vue';
import HeadingBlock from './blocks/HeadingBlock.vue';
import ImageBlock from './blocks/ImageBlock.vue';
import ListBlock from './blocks/ListBlock.vue';
import QuoteBlock from './blocks/QuoteBlock.vue';
import SliderBlock from './blocks/SliderBlock.vue';
import SpacerBlock from './blocks/SpacerBlock.vue';
import TableBlock from './blocks/TableBlock.vue';
import TableV2Block from './blocks/TableV2Block.vue';
import TextBlock from './blocks/TextBlock.vue';
import VideoBlock from './blocks/VideoBlock.vue';
import FormBlock from './blocks/FormBlock.vue';

export default {
  name: 'ContentRenderer',
  props: {
    block: {
      type: Object,
      required: true,
    },
    rowType: {
      type: String,
      default: '',
    },
    colIndex: {
      type: Number,
      default: 0,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Lightbox,
    tsCol,
    tsRow,
    Breadcrumbs,
    'ts-accordion': AccordionSectionBlock,
    'ts-button': ButtonBlock,
    'ts-card': CardBlock,
    'ts-divider': DividerBlock,
    'ts-embed': EmbedBlock,
    'ts-gallery': GalleryBlock,
    'ts-heading': HeadingBlock,
    'ts-image': ImageBlock,
    'ts-list': ListBlock,
    'ts-quote': QuoteBlock,
    'ts-slider': SliderBlock,
    'ts-spacer': SpacerBlock,
    'ts-table': TableBlock,
    'ts-table-v2': TableV2Block,
    'ts-text': TextBlock,
    'ts-video': VideoBlock,
    'ts-form': FormBlock,
  },
  computed: {
    ...mapGetters(['getGlobals']),
  },
};
</script>
