<template>
  <div :class="getColumnOrRowClasses">
    <div
      v-for="(column, colIndex) in row.config"
      :key="`${colIndex}-${row.rowId}`"
      :class="getComponentClassName(row.component)"
      class="column"
    >
      <div
        v-for="block in column"
        :key="block.id"
        :style="blockStyle(block)"
        :class="standardWidth(row.type, 'block')"
        class="block"
      >
        <RowRenderer
          v-if="isLayoutBlock(block)"
          :row="block"
          :nested-row="!isBaseColumn"
          :level="Number(level) + 1"
        />
        <ContentRenderer
          v-else-if="block.config"
          :block="block"
          :row-type="row.component"
          :col-index="colIndex"
          :level="level"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { blockStyle, isLayoutBlock } from './helpers/page-builder-helpers';
import ContentRenderer from './ContentRenderer.vue';

export default {
  name: 'RowRenderer',
  components: {
    ContentRenderer,
  },
  props: {
    row: {
      type: [Object, Array],
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
    nestedRow: Boolean,
  },
  computed: {
    isBaseColumn() {
      return this.row.component === 'BASE_COLUMN';
    },
    getColumnOrRowClasses() {
      if (this.isBaseColumn) {
        return ['column', 'new-builder-root-column'];
      }

      return [
        'row',
        `${this.row.component}-row`,
        `row-level-${this.level}`,
        this.standardWidth(this.row.component, 'row'),
        { 'nested-row': !!this.nestedRow },
      ];
    },
  },
  methods: {
    blockStyle,
    isLayoutBlock,
    getComponentClassName(component) {
      if (!component) return '';

      const kebabCaseName = component.replace('_', '-');
      return kebabCaseName.toLowerCase();
    },
    standardWidth(layout, location) {
      const stdRow = layout !== 'ts-full-width' && location === 'row';
      return stdRow ? 'standard-row' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/breakpoints';

:deep(a) {
  color: var(--links-color);
}
.ts-one-column {
  width: 100%;
}
.ts-two-column {
  width: 50%;
  &.column:nth-child(2) {
    margin-left: 24px;
  }
}
.ts-three-column {
  width: 33.3%;
  margin-left: 24px;
  &.column:nth-child(1) {
    margin-left: 0;
  }
}
.ts-four-column {
  width: 25%;
  margin-left: 24px;
  &.column:nth-child(1) {
    margin-left: 0;
  }
}
.ts-left-sidebar {
  &.column:nth-child(1) {
    width: 30%;
  }
  &.column:nth-child(2) {
    width: 70%;
    margin-left: 24px;
  }
}
.ts-right-sidebar {
  &.column:nth-child(1) {
    width: 70%;
  }
  &.column:nth-child(2) {
    width: 30%;
    margin-left: 24px;
  }
}

.standard-row {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  max-width: 1920px;
  &:first-child {
    margin-top: 40px;
  }
}
.row {
  word-break: break-word;
  .column:first-child {
    .block {
      margin-left: 0;
    }
  }
}
.column {
  vertical-align: top;
}
.block {
  overflow: hidden;
  margin-bottom: 20px;
}

.new-builder-root-column {
  width: 100%;
  > .base-column:first-child > .block {
    margin-top: 40px;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .block {
    margin-bottom: 10px;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .standard-row {
    &:first-child {
      margin-top: 0;
    }
    &.nested-row {
      width: 100%;
      margin-top: 0;
    }
  }

  .content-level-0 {
    width: 85%;
    margin: 10px auto;
    max-width: 1920px;
  }
}

/** Extra Small to Large Phones **/
@include screen('xs') {
  .row {
    display: block;
  }
  .column {
    display: inline-block;
    width: 100%;
  }
  .ts-left-sidebar {
    &.column:nth-child(1) {
      width: 100%;
    }
    &.column:nth-child(2) {
      width: 100%;
      margin-left: 0;
    }
  }
  .ts-right-sidebar {
    &.column:nth-child(1) {
      width: 100%;
    }
    &.column:nth-child(2) {
      width: 100%;
      margin-left: 0;
    }
  }
  .ts-two-column {
    &.column:nth-child(2) {
      margin-left: 0;
    }
  }
  .ts-three-column,
  .ts-four-column {
    &.column {
      margin-left: 0;
    }
  }
} /** Small to Medium Tablets **/
@include screen('sm') {
  .ts-four-column-row,
  .ts-three-column-row {
    display: block;
  }
  .ts-four-column,
  .ts-three-column {
    display: inline-block;
    width: calc(50% - 12px);
    &.column:nth-child(3) {
      margin-left: 0;
    }
  }
  .ts-three-column {
    &.column:nth-child(3) {
      display: block;
      margin: auto;
      width: 50%;
    }
  }
}
</style>
