const layouts = [
  'ts-one-column',
  'ts-two-column',
  'ts-three-column',
  'ts-four-column',
  'ts-left-sidebar',
  'ts-right-sidebar',
  'ts-full-width',
  'BASE_COLUMN',
];

const isLayoutBlock = (block) => {
  return layouts.includes(block.component);
};

const getLayoutMarginPadding = (block, property = 'margin') => {
  if (!isLayoutBlock(block)) return {};

  const defaultValues = {
    margin: '10px',
    padding: '0',
  };
  const blockProperties = block?.properties?.[property];
  if (!blockProperties) {
    return { [property]: defaultValues[property] };
  }

  const { linked, top, bottom, left, right, value } = blockProperties;
  if (linked) {
    return { [property]: `${value}px` };
  }

  return {
    [`${property}Top`]: `${top}px`,
    [`${property}Bottom`]: `${bottom}px`,
    [`${property}Left`]: `${left}px`,
    [`${property}Right`]: `${right}px`,
  };
};

const blockStyle = (block) => {
  return {
    ...(block.name === 'Form' ? { overflow: 'visible' } : {}),
    ...getLayoutMarginPadding(block, 'margin'),
    ...getLayoutMarginPadding(block, 'padding'),
  };
};

export { blockStyle, isLayoutBlock };
