<template>
  <div>
    <fc-form-renderer
      v-if="showRenderer"
      v-model:answers="answers"
      v-model:answers-form-data-format="answersFormData"
      :form="activeForm"
      :questions="activeQuestions"
      :description="getDescription"
      :primary-color="getThemeHeadingColor"
      :title="formTitle"
      :privacy-policy-url="privacyPolicyUrl"
      :privacy-policy-target="privacyPolicyTarget"
      :invalid-otp-code="invalidOtpCode"
      hide-header
      @submit="handleFormSubmit"
      @send-otp-code="sendCode"
      @resend-otp-code="sendCode"
      @confirm-otp-code="confirmCode"
      @update:answers-form-data-format="answersFormData = $event"
    />
    <div
      v-if="submitSuccess"
      class="status"
    >
      <success-check />
      <p class="status-text">
        {{ $t('forms.submitSuccess') }}
      </p>
    </div>
    <div
      v-if="submitError"
      class="status"
    >
      <error-bang />
      <p class="status-text">
        {{ $t('forms.submitError') }}
      </p>
    </div>
    <UnavailableForm
      v-if="unavailableForm"
      :message="unavailableFormMessage"
      hide-btn
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';
import JsonApiResponseConverter from 'json-api-response-converter';
import SuccessCheck from '../../pages/forms/components/svg/SuccessCheck.vue';
import ErrorBang from '../../pages/forms/components/svg/ErrorBang.vue';
import UnavailableForm from '../../pages/forms/views/UnavailableForm.vue';

export default {
  name: 'FormBlock',
  components: {
    SuccessCheck,
    ErrorBang,
    UnavailableForm,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeForm: null,
      activeQuestions: '',
      activeChoices: [],
      answers: [],
      privacyPolicyUrl: 'https://www.apptegy.com/privacy-policy/',
      privacyPolicyTarget: '_blank',
      invalidOtpCode: false,
      respondentEmail: '',
      viewedAt: new Date().toISOString(),
      answersFormData: new FormData(),
      submitSuccess: false,
      submitError: false,
      unavailableForm: false,
    };
  },
  computed: {
    ...mapGetters(['getSecondaryOrgId', 'getGlobals', 'getPathPrefix', 'getThemeHeadingColor']),
    ...mapGetters('translation', ['getLanguage', 'getLocale']),
    showRenderer() {
      return (
        this.activeForm && this.activeQuestions && !this.submitSuccess && !this.submitError && !this.unavailableForm
      );
    },
    formTitle() {
      return this.activeForm.attributes.name;
    },
    getDescription() {
      return this.activeForm.attributes?.description || '';
    },
    unavailableFormMessage() {
      return this.$t('forms.unavailableFormTitle', { formTitle: `"${this.formTitle}"` });
    },
    submitUrl() {
      const uniqueId = this.activeForm?.attributes?.unique_id;
      if (!uniqueId) return '';
      return `${useRuntimeConfig().public.FORMS_SERVICE_API_URL}api/v1/forms/${uniqueId}/responses`;
    },
    orgParam() {
      return {
        secondary_organization_id: this.$store.getters.getSecondaryOrgId,
      };
    },
  },
  async mounted() {
    if (!isEmpty(this.config)) {
      await this.fetchForm();

      if (this.unavailableForm) return;

      await this.fetchQuestions();
    }
  },
  methods: {
    async fetchForm() {
      const configFormIndex = get(this.config, 'form.form_id', '-1');

      try {
        const { data } = await this.$axios.get(
          `${useRuntimeConfig().public.FORMS_SERVICE_API_URL}api/v1/forms/${configFormIndex}`,
          {
            params: {
              locale: this.getLocale !== 'en' ? this.getLocale : undefined,
              status: 'SURVEY_STATUS_ACTIVE',
              ...this.orgParam,
            },
          },
        );
        this.activeForm = data.data;

        if (this.activeForm.attributes.status !== 'SURVEY_STATUS_ACTIVE') {
          throw new Error('Form not currently active');
        }
      } catch (error) {
        this.unavailableForm = true;
      }
    },
    async fetchQuestions() {
      try {
        const questionsResponse = await this.$axios.get(
          `${useRuntimeConfig().public.FORMS_SERVICE_API_URL}api/v1/forms/${this.activeForm.id}/elements`,
          {
            params: {
              locale: this.getLocale !== 'en' ? this.getLocale : undefined,
              include: 'element_choices',
              ...this.orgParam,
            },
          },
        );
        this.activeQuestions = new JsonApiResponseConverter(questionsResponse.data).formattedResponse;
        this.activeChoices = this.activeQuestions.reduce(
          (map, { element_choices, id }) => ({
            ...map,
            [id]: element_choices,
          }),
          {},
        );
        this.answers = this.activeQuestions.map((question) => ({
          question_id: question.id,
          answer: '',
        }));
      } catch (err) {
        console.error(err);
      }
    },
    async handleFormSubmit() {
      try {
        const answers = this.answersFormData;
        answers.append('data[attributes][viewed_at]', this.viewedAt);
        await this.$axios.post(this.submitUrl, answers, {
          headers: {
            'X-otp-email': this.respondentEmail,
            'Content-Type': 'multipart/form-data',
          },
        });
        this.submitSuccess = true;
      } catch (error) {
        this.submitError = true;
      }
    },
    async sendCode(email) {
      this.respondentEmail = this.respondentEmail || email;
      await this.$axios.post(`${useRuntimeConfig().public.FORMS_SERVICE_API_URL}api/v1/email_verification`, {
        data: {
          attributes: {
            email: this.respondentEmail,
            form_unique_id: this.activeForm?.attributes?.unique_id,
          },
        },
      });
    },
    async confirmCode(code) {
      this.invalidOtpCode = false;
      const {
        data: { success },
      } = await this.$axios.post(`${useRuntimeConfig().public.FORMS_SERVICE_API_URL}api/v1/otp_verification`, {
        data: {
          attributes: {
            email: this.respondentEmail,
            otp: code,
          },
        },
      });
      if (!success) {
        this.invalidOtpCode = true;
      } else {
        this.handleFormSubmit();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status-text {
  display: inline-block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 900;
  letter-spacing: 0.8px;
}
</style>
