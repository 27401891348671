<template>
  <div>
    <div :style="getSpacerStyles" />
  </div>
</template>

<script>
export default {
  name: 'SpacerBlock',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getSpacerStyles() {
      const backgroundColor = !!this.config.spacer.isTransparent ? '#ffffff00' : this.config.spacer.backgroundColor;
      return {
        height: this.config.spacer.height + 'px',
        'background-color': backgroundColor,
      };
    },
  },
};
</script>
